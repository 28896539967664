<template>
    <div style="margin-top: 100px; margin-bottom: 100px">
        <div class="container-bg" style="margin-top: 5% !important; width: 45%; margin: auto">
            <div class="container-fg">
                <div style="display: flex; flex-direction: row">
                    <img style="width: 130px; margin-bottom: 1%" src="../../assets/logos/getac-logo.svg" />
                    <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">PAY QUOTE</div>
                </div>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <div style="display: flex; flex-direction: row">
                    <div style="display: block; margin: auto">
                        <p class="text-h7 nav-font text-center" style="display: block; margin: auto; margin-top: 25%"
                            >QUOTE SUCCESSFULLY</p
                        >
                        <h1 style="font-size: 36px; letter-spacing: -0.08rem; margin-bottom: 10%" class="text-center"
                            >PAID & COMPLETED</h1
                        >
                        <br />
                        <v-row>
                            <v-col class="text-center">
                                <v-btn
                                    color="primary"
                                    outlined
                                    class="text-center"
                                    style="margin-bottom: 25%"
                                    @click="returnHome"
                                    >Home</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuotePaymentSuccess',
    data() {
        return {}
    },
    methods: {
        returnHome() {
            window.location.reload()
        }
    }
}
</script>
